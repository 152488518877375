<template>
    <div class="bonus-modal">
        <component
            :is="icon"
            class="bonus-modal__icon"
        />
        <div
            class="bonus-modal__body"
            v-html="text"
        />
    </div>
</template>

<script>
    import Vue from 'vue'
    import moment from 'moment'

    import WButton from 'Components/js/ui/Button.vue'
    import WModal from 'Components/js/ui/Modal.vue'

    import MoneyOnFireIcon from 'Assets/icons/money-on-fire.svg?component'

    moment.locale('ru')

    export default {
        name: 'AppBonusModal',
        components: {
            MoneyOnFireIcon
        },
        props: {
            title: {
                type: String,
                required: true
            },
            description: {
                type: String,
                required: true
            },
            iconAlias: {
                type: String,
                default: 'money'
            },
            expirationDate: {
                type: Date,
                default: () => new Date('2021-03-03')
            },
            sumRemaining: {
                type: Number,
                default: 0.00
            }
        },
        computed: {
            text () {
                const start = moment()
                const end = moment(this.expirationDate)
                const duration = moment.duration(start.diff(end)).humanize(false)

                return this.description
                    .replaceAll('[[sum_remaining]]', this.sumRemaining)
                    .replaceAll('[[time_remaining]]', duration)
            },
            icon () {
                switch (this.iconAlias) {
                    default:
                        return MoneyOnFireIcon
                }
            }
        },
        mounted () {
            const ModalClass = Vue.extend(WModal)
            const Modal = new ModalClass({
                propsData: {
                    size: 'small',
                    decoration: 'wavy-purple'
                }
            })
            const header = this.$createElement('h2', {
                class: [ 'bonus-modal__header' ],
                domProps: { innerHTML: this.title }
            })
            const footer = this.$createElement(WButton, {
                on: { click: Modal.close }
            }, [ 'Спасибо!' ])

            Modal.$slots.header = [ header ]
            Modal.$slots.body = [ this._vnode ]
            Modal.$slots.footer = [ footer ]
            Modal.$mount()
        }
    }
</script>

<style lang="scss">
    @import 'Assets/scss/ui/_typography';

    .bonus-modal {
        padding-top: rem(20);

        &__header {
            color: #fff;
            font-size: rem(20);
            font-weight: bold;
            line-height: 120%;
            margin: 0;
            padding-top: 8px;
        }

        &__body {
            color: #333;
            font-size: rem(16);
            line-height: 130%;
            padding: 35px 0;
            text-align: left;
        }

        &__icon {
            width: 109px;
            height: auto;

            .money-1,
            .money-2,
            .money-3 {
                transform-box: fill-box;
                transform-origin: center;
                animation: scale-up-down 2s infinite ease-in-out;
            }

            .money-2 {
                animation-delay: .5s;
            }

            .money-3 {
                animation-delay: 1s;
            }

            .fire-1,
            .fire-2,
            .fire-3 {
                transform-box: fill-box;
                transform-origin: bottom;
            }

            .fire-1 {
                animation: scale-up-down-2 1.5s infinite ease-in;
            }

            .fire-2 {
                animation: flip-flop 4s infinite cubic-bezier(.86,.33,.13,.72);
            }

            .fire-3 {
                animation: heartbeat 1s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
            }
        }
    }

    @keyframes flip-flop {
        0% { transform: rotateY(-180deg) scaleY(1) }
        50% { transform: rotateY(0deg) scaleY(.8) }
        100% { transform: rotateY(180deg) scaleY(1) }
    }

    @keyframes scale-up-down {
        0% { transform: scale(1) }
        45% { transform: scale(1.03) }
        100% { transform: scale(1) }
    }

    @keyframes scale-up-down-2 {
        0% { transform: scaleY(0.85) }
        25% { transform: scaleY(0.95) }
        35% { transform: scaleY(0.93) }
        65% { transform: scaleY(1) }
        100% { transform: scaleY(0.85) }
    }

    @keyframes heartbeat {
        0% { transform: scale(0.95) }
        5% { transform: scale(1.1) }
        39% { transform: scale(0.85) }
        45% { transform: scale(1) }
        60% { transform: scale(0.95) }
        100% { transform: scale(0.9) }
    }
</style>
