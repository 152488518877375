<template>
    <div
        :class="{ 
            'button__wrapper': true,
            'button__wrapper--wide': wide
        }"
    >
        <component
            :is="href ? 'a' : 'button'"
            :href="!disabled ? href : null"
            :type="type || null"
            :class="{ 
                'button': true,
                'button--outline': outline,
                'button--transparent': transparent,
                'button--disabled': disabled,
                'button--wide': wide,
                ['button--' + color]: true,
                ['button--to-' + colorTo]: colorTo !== 'none'
            }"
            @click="click"
        >
            <div 
                v-if="colorTo !== 'none'"
                class='button__gradient-background'
            />
            <span
                v-if="!loading && state !== 'loading'"
                class="button__text"
            >
                <slot />
            </span>
            <div
                v-else
                class="button__icon"
                v-html="icons.loader"
            />
        </component>
    </div>
</template>

<script>
    import LoaderIcon from '!!svg-inline-loader!Components/img/loader.svg'

    export default {
        name: 'WButton',
        props: {
            color: {
                type: String,
                default: 'green',
                validator: (value) => {
                    const types = [ 'green', 'gray', 'blue', 'red', 'transparent' ]

                    if (types.indexOf(value) === -1) {
                        console.error(`[Vue warn]: prop "color" must be any of following "${types.join(', ')}"`)
                        return false
                    }

                    return true
                }
            },
            colorTo: {
                type: String,
                default: 'none',
                validator: (value) => {
                    const types = [ 'none', 'green' ]

                    if (types.indexOf(value) === -1) {
                        console.error(`[Vue warn]: prop "color-to" must be any of following "${types.join(', ')}"`)
                        return false
                    }

                    return true
                }
            },
            loading: Boolean,
            disabled: Boolean,
            wide: {
                type: Boolean,
                default: true
            },
            text: String,
            href: String,
            type: String,
            outline: {
                type: Boolean,
                default: false
            },
            transparent: {
                type: Boolean,
                default: false
            },
            onClick: {
                type: Function,
                default: function (e) {
                    if (this.href || this.disabled) return false

                    this.$emit('click', e)
                }
            }
        },
        data () {
            return {
                icons: {
                    loader: LoaderIcon
                },
                state: 'normal'
            }
        },
        methods: {
            click (e) {
                e.loading = (isLoading = null) => {
                    if (isLoading === null) return this.state === 'loading'

                    this.state = isLoading ? 'loading' : 'normal'
                }

                this.onClick(e)
            }
        }
    }
</script>

<style lang="scss">
    @import 'Assets/scss/ui/_typography';
    @import 'Assets/scss/ui/_form-elements';

    $default-color: #fff;
    $default-stroke: #fff;
    $default-outline-color: #2fd36c;
    $default-bg: linear-gradient(180deg, #4ce986 0%, #2fd36c 100%);
    $default-shadow: 0 3px 12px rgba(47, 211, 108, .5);

    $disabled-color: #fff;

    $gray-color: #333;
    $gray-stroke: #333;
    $gray-outline-color: #333;
    $gray-bg: #f3f3f3;

    $green-color: #fff;
    $green-stroke: #fff;
    $green-outline-color: #2fd36c;
    $green-bg: linear-gradient(180deg, #4ce986 0%, #2fd36c 100%);
    $green-shadow: 0 3px 12px rgba(47, 211, 108, .5);

    $blue-color: #fff;
    $blue-stroke: #fff;
    $blue-outline-color: #0Ca9ef;
    $blue-bg: linear-gradient(0deg, #0Ca9ef 0%, #4dc3f8 100%);
    $blue-shadow: 0 4px 20px rgba(10, 164, 233, .3);

    $red-color: #fff;
    $red-stroke: #fff;
    $red-outline-color: #ff2c2c;
    $red-bg: linear-gradient(0deg, #ff2c2c 0%, #f94747 100%);
    $red-shadow: 0 4px 20px rgba(138, 36, 36, .3);

    $white-color: #fff;
    $white-stroke: #fff;
    $white-outline-color: #fff;;
    $white-bg: #fff;
    $white-shadow: 0 4px 20px rgba(255, 255, 255, .3);
    $white-border: 2px solid #fff;

    $height-raw: 50;
    $height: rem($height-raw);
    $border-radius: rem($height-raw * 2);
    $border-width: 2px;

    .button {
        align-items: center;
        background: $default-bg;
        border-radius: $border-radius;
        box-sizing: border-box;
        stroke: $default-stroke; // for loader
        color: $default-color;
        cursor: pointer;
        display: flex;
        font-family: $font-PT-Sans;
        font-weight: bold;
        font-size: rem(18);
        height: 100%;
        justify-content: center;
        line-height: 1;
        padding: 0 rem(20);
        position: relative;
        text-align: center;
        text-decoration: none;
        transition: color .2s ease-in-out;

        &::before, &::after {
            border-radius: $border-radius;
            content: '';
            opacity: 0;
            position: absolute;
            transition: opacity .2s ease-in-out;
        }

        &::before {
            background-color: #fff;
            bottom: $border-width;
            left: $border-width;
            right: $border-width;
            top: $border-width;
            z-index: 0;
        }

        &::after {
            bottom: 0;
            box-shadow: $default-shadow;
            left: 0;
            right: 0;
            top: 0;
        }

        &:hover {
            color: $default-color;
        }

        &--wide {
            width: 100%;
        }

        &--outline {
            color: $default-outline-color;
            stroke: $default-outline-color;
            transition: color .2s ease-in-out;

            &::before {
                opacity: 1;
            }

            &:hover:not(.button--disabled) {
                color: $default-color;
            }

            &:hover:not(.button--disabled)::before {
                opacity: 0;
            }

            &.button--disabled {
                color: $disabled-color;
            }

            &.button--gray {
                color: $gray-outline-color;
                stroke: $gray-outline-color;
            }

            &.button--green {
                color: $green-outline-color;
                stroke: $green-outline-color;
            }

            &.button--blue {
                color: $blue-outline-color;
                stroke: $blue-outline-color;
            }

            &.button--red {
                color: $red-outline-color;
                stroke: $red-outline-color;
            }

            &.button--white {
                color: $white-outline-color;
                stroke: $white-outline-color;
            }
        }

        &--gray {
            color: $gray-color;
            background: $gray-bg;
            stroke: $gray-stroke;
        }

        &--green {
            color: $green-color;
            background: $green-bg;
            stroke: $green-stroke;

            &::after {
                box-shadow: $green-shadow;
            }
        }

        &--blue {
            color: $blue-color;
            background: $blue-bg;
            stroke: $blue-stroke;

            &::after {
                box-shadow: $blue-shadow;
            }
        }

        &--red {
            color: $red-color;
            background: $red-bg;
            stroke: $red-stroke;

            &::after {
                box-shadow: $red-shadow;
            }
        }

        &--white {
            color: $white-color;
            background: $white-bg;
            stroke: $white-stroke;

            &::after {
                box-shadow: $white-shadow;
            }
        }

        &--transparent {
            background: transparent;
            border: 2px solid #fff;
            transition: background-color 0.2s ease-in-out;

            &:hover {
                background-color: rgba(255,255,255, 0.16);
            }

            &::after {
                box-shadow: none;
            }
        }

        &--to-green {
            &:hover:not(.button--disabled) {
                color: $green-color;
                stroke: $green-stroke;
        
                &::after {
                    box-shadow: $green-shadow;
                }
            }
        }

        &:hover:not(.button--disabled)::after,
        &:hover .button__gradient-background {
            opacity: 1;
        }

        &--disabled {
            cursor: default;
            pointer-events: none;
        }

        &--disabled:not(.button--outline) {
            background: #c3c3c3;
        }

        &__wrapper {
            // This line to make multiple buttons in one row expand to full width
            &--wide {
                width: 100%;
            }

            height: $height;
        }

        &__gradient-background {
            background: linear-gradient(180deg, #4ce986 0%, #2fd36c 100%);
            border-radius: 100px;
            position: absolute;
            top: 0;
            transition: opacity .2s linear;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
            opacity: 0;
        }

        &__text {
            align-items: center;
            display: inline-flex;
            z-index: 1;
        }

        &__icon {
            width: 23px;
            height: 23px;
            margin: 0 10px;
            z-index: 3;
        }
    }
</style>
