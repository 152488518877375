var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.inputType !== 'hidden'),expression:"inputType !== 'hidden'"}],staticClass:"workle-input"},[_c('label',{class:{
            'input__wrapper': true,
            'input__wrapper--bold': _vm.bold
        }},[_c('input',{ref:"input",class:( _obj = {
                'input': true,
                'input--bold': _vm.bold,
                'input--filled': _vm.value,
                'input--no-text': !_vm.text.length,
                'input--error': _vm.errors.length && !_vm.disabled,
                'input--disabled': _vm.disabled
            }, _obj['input--' + _vm.size] = _vm.size, _obj ),attrs:{"autocomplete":"off","type":_vm.inputType,"name":_vm.name,"disabled":_vm.disabled,"readonly":_vm.readonly,"placeholder":!_vm.disabled ? _vm.placeholder : ''},domProps:{"value":_vm.value},on:{"input":_vm.onInput,"change":_vm.onChange,"blur":_vm.onBlur,"copy":_vm.onCopy}}),_vm._v(" "),(_vm.text.length)?_c('div',{class:{
                'input__text': true,
                'input__text--bold': _vm.bold
            }},[_vm._t("text",[_vm._v("\n                "+_vm._s(_vm.text)+"\n            ")])],2):_vm._e(),_vm._v(" "),(_vm.iconShow)?_c('div',{class:{
                'input__icon-wrapper': true,
                'input__icon-wrapper--error': _vm.errors.length && !_vm.disabled
            }},[(_vm.passwordSwitchShow)?_c('div',{class:{
                    'input__icon': true,
                    'input__icon--password-visible': !_vm.passwordHidden
                },domProps:{"innerHTML":_vm._s(_vm.passwordHidden ? _vm.icons.eyeClosed : _vm.icons.eyeOpened)},on:{"click":function($event){$event.preventDefault();_vm.passwordHidden = !_vm.passwordHidden}}}):_vm._e(),_vm._v(" "),(_vm.searchable)?_c('div',{staticClass:"input__icon",class:{
                    'input__icon': true,
                    'input__icon--bold': _vm.bold
                }},[_c('search-icon',{on:{"click":_vm.onSearchIconClick}})],1):_vm._e(),_vm._v(" "),_vm._t("icon")],2):_vm._e()]),_vm._v(" "),(_vm.errors.length && !_vm.disabled)?_c('div',{staticClass:"input__errors-wrapper"},_vm._l((_vm.errors),function(error,index){return _c('div',{key:index,staticClass:"input__error",domProps:{"innerHTML":_vm._s(error)}})}),0):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }