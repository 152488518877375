var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"workle-select"},[_c('div',{staticClass:"select__wrapper"},[_c('div',{class:{
                'select': true,
                'select--filled': _vm.value,
                'select--error': _vm.errors.length && !_vm.disabled,
                'select--disabled': _vm.disabled
            }},[_c('div',{class:{
                    'select__choosen': true,
                    'select__choosen--hidden': _vm.searchEnabled && !_vm.collapsed,
                    'select__choosen--disabled': _vm.disabled,
                    'select__choosen--searchable': _vm.searchEnabled
                },on:{"mousedown":function($event){$event.preventDefault();return _vm.onMousedown($event)}}},[_vm._t("selection",[_vm._v("\n                    "+_vm._s(_vm.selected)+"\n                ")])],2),_vm._v(" "),_c('div',{class:{
                    'select__search': true,
                    'select__search--hidden': !_vm.searchEnabled
                }},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search),expression:"search"}],ref:"search",staticClass:"select__search-input",attrs:{"autocomplete":"nope","type":"text","name":_vm.name},domProps:{"value":(_vm.search)},on:{"blur":_vm.onBlur,"input":[function($event){if($event.target.composing){ return; }_vm.search=$event.target.value},_vm.onInput],"focus":_vm.onFocus,"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.onEnter($event)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"escape",undefined,$event.key,undefined)){ return null; }return _vm.onEscape($event)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"arrow-up",undefined,$event.key,undefined)){ return null; }return _vm.onArrowKeyUp($event)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"arrow-down",undefined,$event.key,undefined)){ return null; }return _vm.onArrowKeyDown($event)}]}})]),_vm._v(" "),(_vm.searchable)?_c('div',{staticClass:"select__icon-wrapper select__icon-wrapper--search"},[_c('search-icon',{class:{
                        'select__icon': true,
                        'select__icon--search': true
                    }})],1):_c('div',{staticClass:"select__icon-wrapper select__icon-wrapper--chevron"},[_c('pointer-icon',{class:{
                        'select__icon': true,
                        'select__icon--chevron': true,
                        'select__icon--chevron-active': !_vm.collapsed
                    }})],1),_vm._v(" "),_c('transition',{attrs:{"name":"select"}},[(!_vm.collapsed)?_c('div',{ref:"options",staticClass:"select__options",style:(_vm.optionStyles)},_vm._l((_vm.filteredOptions),function(option,index){return _c('div',{key:index,class:{
                            'select__option': true,
                            'select__option--selected': option.isSelected,
                            'select__option--highlighted': option.isHighlighted
                        },on:{"mousedown":function($event){return _vm.onSelect(option)}}},[_vm._t("option",[_c('span',[_vm._v(_vm._s(option.text))])],{"option":option})],2)}),0):_vm._e()])],1),_vm._v(" "),_c('span',{class:{
                'select__text': true,
                'select__text--active': _vm.value || (_vm.searchEnabled && !_vm.collapsed),
                'select__text--error': _vm.errors.length && !_vm.disabled
            },domProps:{"innerHTML":_vm._s(_vm.text)}})]),_vm._v(" "),(_vm.errors.length && !_vm.disabled)?_c('div',{staticClass:"input__errors-wrapper"},_vm._l((_vm.errors),function(error,index){return _c('div',{key:index,staticClass:"input__error",domProps:{"innerHTML":_vm._s(error)}})}),0):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }