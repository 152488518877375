var render = function () {
var _obj, _obj$1, _obj$2;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app-dropdown-menu"},[_c('div',{ref:"$handler",staticClass:"app-dropdown-menu__handler",on:{"click":_vm.onToggle}},[_vm._t("default")],2),_vm._v(" "),_c('transition',{attrs:{"name":"fade"}},[(_vm.isVisible)?_c('div',{class:( _obj = {
                'app-dropdown-menu__list-positioning-helper': true
            }, _obj[("app-dropdown-menu__list-positioning-helper--" + _vm.position)] = true, _obj )},[_c('div',{class:( _obj$1 = {
                    'app-dropdown-menu__list-arrow': true
                }, _obj$1[("app-dropdown-menu__list-arrow--" + _vm.position)] = true, _obj$1 )}),_vm._v(" "),_c('div',{ref:"$list",class:( _obj$2 = {
                    'app-dropdown-menu__list': true
                }, _obj$2[("app-dropdown-menu__list--" + _vm.position)] = true, _obj$2 ),style:({
                    minWidth: _vm.width ? (_vm.width + "px") : null,
                    top: _vm.offsetBottom ? (_vm.offsetBottom + "px") : null
                }),attrs:{"tabindex":"0"},on:{"focusout":_vm.onHide}},_vm._l((_vm.sections),function(section,index){return _c('section',{key:index,staticClass:"app-dropdown-menu__section"},_vm._l((section),function(item){return _c('div',{key:item.alias,staticClass:"app-dropdown-menu__section-item",style:({ color: item.color || null }),on:{"click":function($event){$event.preventDefault();return _vm.onItemClick(item.alias)}}},[_vm._t("item",[_vm._v("\n                            "+_vm._s(item.name)+"\n                        ")],{"item":item})],2)}),0)}),0)]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }