<template>
    <transition
        name="fade"
        :duration="{
            enter: duration,
            leave: duration
        }"
    >
        <div
            v-show="visible"
            class="w-modal"
            @mousedown.self="close(true)"
            @touchstart.self="close(true)"
        >
            <div
                ref="content"
                :class="{
                    'w-modal__content': true,
                    [ 'w-modal__content--' + size ]: true
                }"
            >
                <div
                    ref="header"
                    :class="{
                        'w-modal__header': true,
                        'w-modal__header--compact': size === 'compact'
                    }"
                >
                    <div
                        v-if="closable"
                        class="w-modal__actions w-modal__actions--top"
                    >
                        <component
                            :is="size === 'compact' ? 'close-icon' : 'close-circle-icon'"
                            :class="{
                                'w-modal__icon-close': true,
                                'w-modal__icon-close--solid': closeSolid,
                            }"
                            @click="close"
                        />
                    </div>
                    <slot name="header">
                        <h2
                            :class="{
                                'w-modal__header-title': true,
                                [ 'w-modal__header-title--' + size ]: true
                            }"
                            v-text="title"
                        />
                    </slot>
                </div>
                <div
                    v-if="size === 'compact'"
                    class="w-modal__separator"
                />
                <div class="w-modal__body">
                    <slot name="body" />
                </div>
                <div class="w-modal__footer">
                    <div
                        v-if="showConfirmButtons"
                        class="w-modal__buttons"
                    >
                        <w-button
                            class="w-modal__buttons-item"
                            @click="confirm"
                        >
                            Да
                        </w-button>
                        <w-button
                            class="w-modal__buttons-item"
                            color="gray"
                            color-to="green"
                            @click="cancel"
                        >
                            Нет
                        </w-button>
                    </div>
                    <slot name="footer" />
                </div>
                <div
                    v-if="decoration !== 'normal'"
                    ref="decoration"
                    :class="{
                        'w-modal__decoration': true,
                        'w-modal__decoration--purple': decoration.includes('purple')
                    }"
                >
                    <wave-mask
                        v-if="decoration.includes('wavy')"
                        class="w-modal__decoration-mask w-modal__decoration-mask--wave"
                    />
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
    import CloseIcon from 'Assets/close.svg?component'
    import CloseCircleIcon from 'Assets/icons/close-circle.svg?component'
    import WaveMask from 'Assets/modal/wave.svg?component'
    import WButton from './Button.vue'

    export default {
        name: 'WModal',
        components: {
            WButton,
            CloseIcon,
            CloseCircleIcon,
            WaveMask
        },
        props: {
            manual: {
                type: Boolean,
                default: true
            },
            decoration: {
                type: String,
                default: 'normal',
                validator: (value) => {
                    const types = [ 'normal', 'wavy-purple' ]

                    if (types.indexOf(value) === -1) {
                        console.error(`[Vue warn]: prop "style" must be any of following "${types.join(', ')}"`)
                        return false
                    }

                    return true
                }
            },
            size: {
                type: String,
                default: 'medium',
                validator: (value) => {
                    const types = [ 'compact', 'small', 'medium', 'large', 'extra-large' ]

                    if (types.indexOf(value) === -1) {
                        console.error(`[Vue warn]: prop "size" must be any of following "${types.join(', ')}"`)
                        return false
                    }

                    return true
                }
            },
            textAlign: {
                type: String,
                default: 'center',
                validator: (value) => {
                    const types = [ 'center', 'left' ]

                    if (types.indexOf(value) === -1) {
                        console.error(`[Vue warn]: prop "textAlign" must be any of following "${types.join(', ')}"`)
                        return false
                    }

                    return true
                }
            },
            closeSolid: {
                type: Boolean,
                default: false,
            },
            closable: {
                type: Boolean,
                default: true
            },
            confirmation: {
                type: Boolean,
                default: false
            },
            title: {
                type: String,
                default: null
            },
            duration: {
                type: Number,
                default: 300
            },
            permanent: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            showConfirmButtons () {
                return this.confirmation && !this.$slots.footer
            }
        },
        data () {
            return {
                closed: false,
                visible: false
            }
        },
        methods: {
            close (fromBackground = false) {
                if (fromBackground && this.permanent) return undefined

                this.toggleBodyLock()
                this.toggleVisibility()
                this.$emit('on-close')
                this.closed = true

                setTimeout(() => {
                    this.$destroy()
                    this.$el.parentNode.removeChild(this.$el)
                }, this.duration)
            },
            toggleVisibility () {
                if (this.closed) return undefined

                this.visible = !this.visible
            },
            toggleBodyLock () {
                if (this.closed) return undefined

                document.body.classList.toggle('w-modal__body-lock')
            },
            confirm () {
                this.$emit('on-confirm')
                this.close()
            },
            cancel () {
                this.$emit('on-cancel')
                this.close()
            },
            setDecorationHeight () {
                if (this.decoration === 'normal') return undefined

                const { header, content, decoration } = this.$refs
                const MASK_HEIGHT = 63
                const ADDITIONAL_PADDING = 20

                const headerHeight = header.offsetHeight
                const modalPaddingTop = window.getComputedStyle(content, null).getPropertyValue('padding-top')
                const padding = parseInt(modalPaddingTop, 10) * 2
                const height = headerHeight + padding + MASK_HEIGHT + ADDITIONAL_PADDING

                decoration.style.height = `${height}px`
            }
        },
        mounted () {
            if (this.manual) {
                document.body.appendChild(this.$el)
            }

            this.toggleBodyLock()
            this.toggleVisibility()

            this.$nextTick(() => this.setDecorationHeight())
        }
    }
</script>

<style lang="scss">
    @import 'Assets/scss/ui/_typography';
    @import 'Assets/scss/_transitions';

    .w-modal {
        align-items: center;
        background-color: rgba(0, 0, 0, .4);
        box-sizing: border-box;
        bottom: 0;
        display: flex;
        justify-content: center;
        left: 0;
        overflow: auto;
        position: fixed;
        right: 0;
        top: 0;
        width: 100%;
        z-index: 999;

        @media only screen and (min-width: 480px) {
            padding-bottom: 20px;
        }

        &__icon-close {
            cursor: pointer;
            fill: #b0b6bb;
            height: 28px;
            transition: fill .2s ease-in-out;
            width: 28px;

            &--solid {
                position: absolute;
                top: -5px;
                right: -5px;
                border-radius: 100%;
                width: 34px;
                height: 34px;
                background: white;
                box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.26);

                path:first-child {
                    display: none;
                }
            }

            &:hover {
                fill: #333;
            }
        }

        &__actions {
            align-self: flex-end;
            display: flex;
            position: relative;
            z-index: 2;
        }

        &__body-lock {
            overflow: hidden;

            // Fix jivosite OVEREXCITING IMPORTANCE
            // TODO: find that guy who developed that to talk him about z-indexes
            .__jivoMobileButton,
            #jvlabelWrap {
                z-index: -1;
            }
        }

        &__content {
            background-color: #fff;
            box-sizing: border-box;
            color: $color-default;
            display: flex;
            flex-direction: column;
            font-family: $font-PT-Sans;
            padding: rem(20);
            position: relative;
            text-align: center;
            width: 100%;
            margin: auto;
            min-height: 100%;
            overflow: hidden;

            @media only screen and (min-width: 480px) {
                border-radius: 14px;
                box-shadow: 0 4px 14px rgba(0, 0, 0, .14);
                margin-top: 20px;
                min-height: auto;
                width: 395px;

                &--small {
                    width: 280px;
                }

                &--large, &--extra-large {
                    width: 480px;
                }
            }

            @media only screen and (min-width: 768px) {
                margin-top: 60px;

                &--large, &--extra-large {
                    width: 600px;
                }
            }
            
            @media only screen and (min-width: 1024px) {
                margin-top: 100px;
            }

            @media only screen and (min-width: 1280px) {
                &--extra-large {
                    width: 1140px;
                }
            }
        }

        &__decoration {
            display: flex;
            flex-direction: column;
            height: 200px;
            justify-content: flex-end;
            left: 0;
            position: absolute;
            right: 0;
            top: 0;
            z-index: 0;

            &--purple {
                background: linear-gradient(180deg, #4a81ff 0%, #3c5bfc 100%);
            }
        }

        &__decoration-mask {
            // ...

            &--wave {
                width: 100%;
                height: auto;
                fill: #fff;
            }
        }

        &__header,
        &__body,
        &__footer {
            z-index: 1;
        }

        &__header {
            z-index: 2;
            display: flex;
            flex-direction: column;
            justify-content: center;

            &--compact {
                flex-direction: row-reverse;
                flex-wrap: wrap;
                justify-content: space-between;
            }
        }

        &__header-title {
            font-size: rem(34);
            font-style: normal;
            font-weight: bold;
            line-height: 130%;
            letter-spacing: 0.02em;
            z-index: 1;

            &--compact {
                font-size: rem(24);
                line-height: 120%;
            }

            &--small {
                font-size: rem(20);
                line-height: 120%;
            }

            &--medium {
                font-size: rem(24);
                line-height: 120%;
            }
        }

        &__separator {
            border-bottom: 1px solid #e8e8e8;
            padding-bottom: 20px;
        }

        &__body {
            flex-grow: 1;
        }

        &__footer {
            display: flex;
            justify-content: center;
        }

        &__buttons {
            padding: 20px 0 0;
            display: flex;
            flex-direction: column;
            width: 100%;

            @media (min-width: 768px) {
                display: block;
            }
        }

        &__buttons-item {
            padding: 0 0 20px;

            @media (min-width: 768px) {
                display: inline-block;
                width: 30%;
            }
        }
    }
</style>
