<template>
    <div
        class="app-calendar"
        @click="openCalendar"
    >
        <div class="app-calendar__input-wrapper">
            <slot name="input" :value="formatedValue">
                <input
                    class="app-calendar__input"
                    type="text"
                    readonly
                    :value="formatedValue"
                />
            </slot>
        </div>
        <transition name='fade'>
            <div
                v-if="calendarIsOpen"
                class="app-calendar__wrapper"
                ref="calendar"
                tabindex="1"
                @focusout="closeCalendar"
            >
                <div class="app-calendar__header">
                    <div
                        class="app-calendar__previous-month icon-arrow-left"
                        @click="previousMonth"
                    />
                    <div class="app-calendar__month">
                        {{ monthAndYear }}
                    </div>
                    <div
                        class="app-calendar__next-month icon-arrow-right"
                        @click="nextMonth"
                    />
                </div>
                <table class="app-calendar__body">
                    <thead class="app-calendar__week-days">
                        <tr class="app-calendar__week-day-names">
                            <td
                                v-for="(name, index) of weekDays"
                                class="app-calendar__day-of-week-name"
                                :key="index"
                            >
                                {{ name }}
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="(week, wIndex) of calendar"
                            :key="wIndex"
                            class="app-calendar__week"
                        >
                            <td
                                v-for="(dayOfWeek, dIndex) of week"
                                :date="dayOfWeek.date"
                                :class="{
                                    'app-calendar__day': true,
                                    'app-calendar__day--range': dayOfWeek.range,
                                    'app-calendar__day--selected': (dayOfWeek.date === begin || dayOfWeek.date === end),
                                    'app-calendar__day--another-month': dayOfWeek.anotherMonth,
                                    'app-calendar__day--today': dayOfWeek.date === today,
                                    'app-calendar__day--disabled': dayOfWeek && !dayOfWeek.canBeChosen
                                }"
                                :key="dIndex"
                                @click="dayOfWeek.canBeChosen && selectDate(dayOfWeek.date)"
                            >
                                {{ dayOfWeek.day }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </transition>
    </div>
</template>

<script>
    import moment from 'moment'
    moment.locale('ru')

    import Vue from 'vue'

    export default {
        props: {
            value: {
                type: [ String, Object ]
            },
            range: {
                type: Boolean,
                default: false
            },
            minDate: {
                type: [String, Date],
                default: undefined
            },
            maxDate: {
                type: [String, Date],
                default: undefined
            }
        },
        data () {
            return {
                weekDays: [ 'пн', 'вт', 'ср', 'чт', 'пт', 'сб', 'вс' ],
                calendarIsOpen: false,
                clickIn: false,
                date: new Date(),
                selectBegin: true,
                begin: '',
                end: ''
            }
        },
        computed: {
            calendar () {
                const weeks = []

                const currentDay = moment(this.date).startOf('month').startOf('week')
                const firstCalendarDay = moment(this.date).startOf('month').startOf('week')
                const lastCalendarDay = moment(this.date).endOf('month').endOf('week')
                const currentMonth = currentDay.clone().endOf('week').month()

                for (let i = 0; i < lastCalendarDay.diff(currentDay); i += 1) {
                    const week = currentDay.diff(firstCalendarDay, 'weeks')
                    
                    weeks[week] = weeks[week] || []
                    weeks[week].push(this.createDay(
                        currentDay, currentDay.month() !== currentMonth
                    ))

                    currentDay.add(1, 'day')
                }

                return weeks
            },
            monthAndYear () {
                const date = moment(this.date)
                const month = date.format('MMMM')

                return `${ month[0].toUpperCase() + month.slice(1) } ${ date.get('year') }`
            },
            today () {
                return moment().format('YYYY-MM-DD')
            },
            formatedValue () {
                const dateFormat = 'DD.MM.YYYY'

                if (!this.value || (!this.value.begin && !this.value.end)) {
                    return this.range ? 'Выберите даты' : 'Выберите дату'
                }

                if (typeof this.value === 'object') {
                    const begin = moment(this.value.begin).format(dateFormat)
                    const end = moment(this.value.end).format(dateFormat)

                    return `${ begin } – ${ end }`
                }

                return moment(this.value).format(dateFormat)
            }
        },
        methods: {
            openCalendar () {
                this.calendarIsOpen = true

                Vue.nextTick(() => {
                    this.$refs.calendar.focus()
                })
            },
            selectDate (date) {
                this.date = new Date(date)

                if (!this.range) {
                    this.begin = date
                    this.$emit('input', this.begin)

                    return undefined
                }

                if (this.selectBegin) {
                    this.begin = date
                    this.end = undefined
                }

                if (!this.selectBegin) {
                    this.end = date
                }

                if (this.begin && this.end && moment(this.begin).diff(this.end) > 0) {
                    [ this.begin, this.end ] = [ this.end, this.begin ]
                }

                if (this.begin && this.end && moment(this.begin).diff(this.end) === 0) {
                    return undefined
                }

                this.selectBegin = !this.selectBegin

                this.$emit('input', {
                    begin: this.begin,
                    end: this.end
                })
            },
            nextMonth () {
                this.date = moment(this.date).add(1, 'month').toDate()
            },
            previousMonth () {
                this.date = moment(this.date).subtract(1, 'month').toDate()
            },
            closeCalendar () {
                this.calendarIsOpen = false
            },
            createDay (momentDate, anotherMonth) {
                const stringDate = momentDate.format('YYYY-MM-DD')
                const range = this.begin && this.end
                    ? moment(stringDate).isBetween(this.begin, this.end, undefined, '()')
                    : false
                const result = {
                    day: momentDate.get('date'),
                    date: stringDate,
                    range,
                    anotherMonth,
                    canBeChosen: (
                        this.minDate
                            ? momentDate.diff(this.minDate) >= 0
                            : true
                    ) && (
                        this.maxDate
                            ? momentDate.diff(this.maxDate) <= 0
                            : true
                    )
                }

                return result
            }
        },
        created () {
            if (!this.value) {
                this.date = moment().diff(this.minDate) < 0 ? new Date(this.minDate) : new Date()
                this.date = moment().diff(this.maxDate) > 0 ? new Date(this.maxDate) : new Date()

                return undefined
            }

            this.date = typeof this.value === 'object' ? this.value.begin : this.value
        }
    }
</script>

<style lang="scss">
    .app-calendar {
        display: inline-block;
        position: relative;

        &__input[readonly] {
            cursor: pointer;
            background-color: inherit;
        }

        &__wrapper {
            position: absolute;
            bottom: 0px;
            left: 0px;
            transform: translateY(100%);
            padding: 5px;
            background-color: white;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
            width: -webkit-fit-content;
            width: -moz-fit-content;
            width: fit-content;
            border-radius: 5px;
            z-index: 100;
        }

        &__body {
            border-collapse: collapse;
        }

        &__header {
            display: flex;
            justify-content: space-between;
            align-items: baseline;
            padding: 5px;
            box-sizing: border-box;
        }

        &__next-month,
        &__previous-month {
            padding: 5px;
            cursor: pointer;
            box-sizing: border-box;

            &:hover {
                opacity: 0.7;
            }

            &:active {
                opacity: 0.5;
            }
        }

        &__week-day-names {
            text-align: center;
            width: 30px;
            height: 30px;
            border-radius: 4px;
            border: none;
            font-weight: bold;
            text-align: center;
            padding: 5px;
            text-transform: uppercase;
        }

        &__month {
            display: flex;
        }

        &__day {
            text-align: center;
            min-width: 30px;
            height: 30px;
            border-radius: 4px;
            border: none;
            cursor: pointer;

            &--range {
                color: #000;
                background-color: #eee;
                border-color: #bbb;
                border-radius: 0;
            }

            &--selected {
                color: #fff !important;
                background-color: #204d74;
                border-color: #122b40;

                &:hover {
                    border-color: #0c1e2c !important;
                    background-color: #1a4060 !important;
                }
            }

            &--another-month {
                color: #a1a1a1;
            }

            &--today {
                color: red;
            }

            &--disabled {
                pointer-events: none;
                color: lighten(#a1a1a1, 30%);
            }

            &:hover {
                background: #eee;
            }
        }
    }
</style>
