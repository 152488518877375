var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{ 
        'button__wrapper': true,
        'button__wrapper--wide': _vm.wide
    }},[_c(_vm.href ? 'a' : 'button',{tag:"component",class:( _obj = { 
            'button': true,
            'button--outline': _vm.outline,
            'button--transparent': _vm.transparent,
            'button--disabled': _vm.disabled,
            'button--wide': _vm.wide
        }, _obj['button--' + _vm.color] = true, _obj['button--to-' + _vm.colorTo] = _vm.colorTo !== 'none', _obj ),attrs:{"href":!_vm.disabled ? _vm.href : null,"type":_vm.type || null},on:{"click":_vm.click}},[(_vm.colorTo !== 'none')?_c('div',{staticClass:"button__gradient-background"}):_vm._e(),_vm._v(" "),(!_vm.loading && _vm.state !== 'loading')?_c('span',{staticClass:"button__text"},[_vm._t("default")],2):_c('div',{staticClass:"button__icon",domProps:{"innerHTML":_vm._s(_vm.icons.loader)}})])],1)}
var staticRenderFns = []

export { render, staticRenderFns }