<template>
    <div
        v-show="inputType !== 'hidden'"
        class="workle-input"
    >
        <label 
            :class="{
                'input__wrapper': true,
                'input__wrapper--bold': bold
            }"
        >
            <input
                ref="input"
                autocomplete="off"
                :value="value"
                :class="{
                    'input': true,
                    'input--bold': bold,
                    'input--filled': value,
                    'input--no-text': !text.length,
                    'input--error': errors.length && !disabled,
                    'input--disabled': disabled,
                    ['input--' + size]: size
                }"
                :type="inputType"
                :name="name"
                :disabled="disabled"
                :readonly="readonly"
                :placeholder="!disabled ? placeholder : ''"
                @input="onInput"
                @change="onChange"
                @blur="onBlur"
                @copy="onCopy"
            >
            <div
                v-if="text.length"
                :class="{
                    'input__text': true,
                    'input__text--bold': bold
                }"
            >
                <slot name="text">
                    {{ text }}
                </slot>
            </div>
            <div
                v-if="iconShow"
                :class="{
                    'input__icon-wrapper': true,
                    'input__icon-wrapper--error': errors.length && !disabled
                }"
            >
                <div
                    v-if="passwordSwitchShow"
                    :class="{
                        'input__icon': true,
                        'input__icon--password-visible': !passwordHidden
                    }"
                    v-html="passwordHidden ? icons.eyeClosed : icons.eyeOpened"
                    @click.prevent="passwordHidden = !passwordHidden"
                />
                <div
                    v-if="searchable"
                    class="input__icon"
                    :class="{
                        'input__icon': true,
                        'input__icon--bold': bold
                    }"
                >
                    <search-icon @click="onSearchIconClick" />
                </div>
                <slot name="icon" />
            </div>
        </label>
        <div
            v-if="errors.length && !disabled"
            class="input__errors-wrapper"
        >
            <div
                v-for="(error, index) in errors"
                class="input__error"
                :key="index"
                v-html="error"
            />
        </div>
    </div>
</template>

<script>
    import Inputmask from 'inputmask'
    import { debounce } from 'Utils/helpers'

    import EyeOpenedIcon from '!!svg-inline-loader!Components/img/eye-opened.svg'
    import EyeClosedIcon from '!!svg-inline-loader!Components/img/eye-closed.svg'
    import SearchIcon from 'Assets/icons/icon-magnifier.svg?component'

    Inputmask.extendDefinitions({
        z: {
            validator: (char) => {
                const regexp = /[АВЕКМНОРСТУХ]/i
                return regexp.test(char)
            },
            casing: 'upper'
        },
        v: {
            validator: (char) => {
                const regexp = /[0-9ABCDEFGHJKLMNPRSTUVWXYZ]/i
                return regexp.test(char)
            },
            casing: 'upper'
        },
        C: {
            validator: (char) => {
                const regexp = /[-| |\u0401\u0451\u0410-\u044f]/i
                return regexp.test(char)
            },
            casing: 'upper'
        }
    })

    export default {
        name: 'WInput',
        components: {
            SearchIcon
        },
        props: {
            value: [ String, Number ],
            type: {
                type: String,
                default: 'text',
                validator: (value) => {
                    const types = [ 'text', 'email', 'password', 'date', 'hidden' ]

                    if (types.indexOf(value) === -1) {
                        console.error(`[Vue warn]: prop "type" must be any of following "${types.join(', ')}"`)
                        return false
                    }

                    return true
                }
            },
            size: {
                type: String,
                default: 'medium',
                validator: (value) => {
                    const types = [ 'small', 'medium' ]

                    if (types.indexOf(value) === -1) {
                        console.error(`[Vue warn]: prop "type" must be any of following "${types.join(', ')}"`)
                        return false
                    }

                    return true
                }
            },
            name: {
                type: String,
                required: true
            },
            text: {
                type: String,
                default: ''
            },
            passwordIcon: {
                type: Boolean,
                default: true
            },
            hasIcon: {
                type: Boolean,
                default: false
            },
            searchable: {
                type: Boolean,
                default: false
            },
            disabled: {
                type: Boolean,
                default: false
            },
            readonly: {
                type: Boolean,
                default: false
            },
            placeholder: {
                type: String,
                default: ' '
            },
            debounce: {
                type: Number,
                default: 0
            },
            errors: {
                type: [ Array, Object ],
                default: () => []
            },
            mask: {
                type: Object,
                default: () => ({
                    unmasked: false,
                    shiftPositions: true,
                    placeholder: '_',
                    input: null,
                    regex: null,
                    oncomplete: null
                })
            },
            bold: {
                type: Boolean,
                required: false,
                default: false
            }
        },
        computed: {
            inputType () {
                if (this.type === 'password') {
                    return this.passwordHidden ? 'password' : 'text'
                }

                return this.type
            },
            passwordSwitchShow () {
                return this.type === 'password'
                    && this.passwordIcon
            },
            iconShow () {
                return this.passwordSwitchShow
                    || this.searchable
                    || this.hasIcon
            }
        },
        data () {
            return {
                $_validation: {},
                passwordHidden: true,
                lastDebounceValue: '',
                icons: {
                    eyeOpened: EyeOpenedIcon,
                    eyeClosed: EyeClosedIcon
                }
            }
        },
        methods: {
            onInput (e) {
                const caret = e.target.selectionStart

                this.$emit('input', e.target.value)
                this.$nextTick(() => {
                    // Fix for inputmask: do not move carret to end if changing middle part of value
                    this.$refs.input.setSelectionRange(caret, caret)

                    if (this.debounce > 0) {
                        this.onDebounce(this, e.target.value)
                    }
                })
            },
            onChange (e) {
                /*
                    TODO: fix that stuff, we must not send 'input' when its actually 'change'
                    This is hotfix for validation. If we'll uncomment 'change' line and comment 'input',
                    it can be tested on registration login field with ctrl+v

                    1) Write bad login (not email/phone)
                    2) unfocus field
                    3) ctrl+v valid phone
                    4) field error not dissapearing
                */

                // this.$emit('change', e.target.value)
                this.$emit('input', e.target.value)
            },
            onDebounce: debounce((context, val) => {
                if (context.lastDebounceValue === val) return undefined

                context.lastDebounceValue = val
                context.$emit('on-debounce', val)
            }, 1000),
            onBlur (e) {
                this.$emit('blur', e)
            },
            onCopy (e) {
                this.$emit('copy', e.target.value)
            },
            onComplete () {
                this.$nextTick(() => {
                    this.mask.oncomplete(this.value)
                })
            },
            onSearchIconClick(e) {
                this.$emit('search-icon-click', e)
            },
            setMask () {
                if (this.mask.input === null) return false
                if (this.disabled && !this.mask.forced) return false

                let options = {
                    mask: this.mask.input,
                    regex: this.mask.regex || null,
                    placeholder: this.mask.placeholder,
                    autoUnmask: this.mask.unmasked,
                    oncomplete: this.mask.oncomplete || undefined,
                    greedy: this.mask.greedy || undefined,
                    shiftPositions: this.mask.shiftPositions,
                    positionCaretOnClick: 'radixFocus'
                }

                if (options.regex !== null) {
                    delete options.mask
                } else {
                    delete options.regex
                }

                if (this.mask.oncomplete) {
                    options.oncomplete = this.onComplete
                }

                new Inputmask(options).mask(this.$refs.input)
            },
            removeMask () {
                Inputmask.remove(this.$refs.input)
            },
            updateMask () {
                this.removeMask()
                this.setMask()
            }
        },
        mounted () {
            this.setMask()
        },
        beforeDestroy () {
            this.removeMask()
        },
        watch: {
            disabled (val, oldval) {
                if (val === oldval) return false

                this.updateMask()
            },
            mask (val, oldval) {
                if (!oldval || oldval === null) return false

                this.updateMask()
            }
        }
    }
</script>

<style lang="scss">
    @import 'Assets/scss/ui/_typography';
    @import 'Assets/scss/ui/_form-elements';

    .input {
        box-sizing: border-box;
        background-color: #fff;
        appearance: none;
        font-size: 0; /* DIRTY PLACEHOLDER HACK */
        font-family: $font-PT-Sans;
        border-radius: $border-radius-input;
        letter-spacing: normal;
        line-height: $height-input-md;
        height: $height-input-md;
        color: $color-default;
        padding: 0 rem(14);
        border: $border-input;
        transition: padding .3s ease 0s;
        outline: none;
        width: 100%;

        &--bold {
            background: #f2f4f5;
            height: 2.5rem;
            line-height: 2.5rem;
            padding-right: 2.5rem;
            transition: all .2s ease;
        }

        &--bold:hover, 
        &--bold:focus, 
        &--bold.input--filled {
            background: white;
        }

        // That selector breaks firefox styles if listed with other selectors
        &:-webkit-autofill {
            font-size: $font-size-default;
            line-height: rem(24);
        }

        &:-moz-autofill {
            font-size: $font-size-default;
            line-height: rem(24);
        }

        &:not(:placeholder-shown),
        &:active,
        &:focus,
        &--filled,
        &--no-text {
            font-size: $font-size-default;
            line-height: rem(24);
        }

        &--small {
            height: 38px;
            padding: 0 10px;

            & + .input__icon-wrapper {
                width: 16px;
            }
        }

        &:not(:placeholder-shown).input--small,
        &:active.input--small,
        &:focus.input--small,
        &--filled.input--small,
        &--no-text.input--small {
            font-size: 14px;
            line-height: 21px;
        }

        &--error {
            border: $border-input-error;
        }

        &--disabled {
            background-color: #eee;
            cursor: default;
            opacity: 1;
        }

        // Disable chrome default highlighting for autocomplete
        &:-webkit-autofill,
        &:-webkit-autofill:hover, 
        &:-webkit-autofill:focus, 
        &:-webkit-autofill:active  {
            box-shadow: 0 0 0 30px #fff inset !important;
        }

        &::-ms-clear,
        &::-ms-reveal {
            display:none;
        }

        &__wrapper {
            display: inline-block;
            line-height: rem(14);
            position: relative;
            width: 100%;
            text-align: left;
        }

        // Icons
        &__icon-wrapper {
            @include input-icon-wrapper;
        }

        &__icon {
            & > svg {
                @include input-icon;
                fill: none;
                stroke: #b0b6bb;

                &:hover {
                    fill: none;
                    stroke: #333;
                }
            }

            &--password-visible > svg {
                fill: #333;
            }

            &--bold {
                position: absolute;
                height: 1.125rem;
                width: 1.125rem;
            }

            &--bold > svg {
                fill: #333;
                cursor: pointer;
            }

            &--bold > svg:hover {
                fill: lighten(#333, 20);
            }
        }

        // Label
        &__text {
            @include label;

            &--bold {
                background: transparent;
                top: .75rem !important;
                font-weight: bold;
                color: #333 !important;
                transition: all .2s ease;
                font-size: 1rem !important;
            }
        }

        // Errors wrapper
        &__errors-wrapper {
            @include errors-wrapper;
        }

        // Label transitions
        // That selector breaks firefox styles if listed with other selectors
        &:-webkit-autofill + &__text {
            @include label-active;
        }

        &:-moz-autofill + &__text {
            @include label-active;
        }

        &:focus + &__text,
        &--filled + &__text,
        &:not(:placeholder-shown):not(.input--disabled) + &__text {
            @include label-active;
        }

        &.input--bold:focus + &__text,
        &--filled.input--bold + &__text,
        &.input--bold:not(:placeholder-shown):not(.input--disabled) + &__text {
            @include label-active;
            background: white;
            opacity: 0;
            pointer-events: none;
        }

        &--error + &__text {
            @include label-error;
        }

        &:disabled:not(.input--filled) + &__text {
            @include label-disabled;
        }
    }
</style>
