<template>
    <div class="switch">
        <div
            class="switch__button"
            :style="buttonStyle"
        />
        <div
            v-for="(item, id) in items"
            :key="id"
            ref="items"
            class="switch__item"
        >
            <div
                :class="{
                    'switch__text': true,
                    'switch__text--active': item.id === selectedItem
                }"
                @click="selectItem(item.id)"
            >
                {{ item.text }}
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'WSwitch',
        props: {
            items: {
                type: Array,
                default: [],
                required: true
            },
            selected: {
                type: Number,
                default: 0
            }
        },
        computed: {
            buttonStyle () {
                const selectedItem = this.selectedItem
                const itemsPositions = this.itemsPositions
                const width = itemsPositions[selectedItem]
                let position = 0

                for (let i = 0; i < selectedItem; i++) {
                    position = position + itemsPositions[i]
                }

                const style = {
                    width: width + 'px',
                    left: position + 'px' 
                }

                return style
            },
            itemsPositions() {
                if (!this.isMounted) return []

                const el = this.$refs.items
                const itemsPosition = el.map(elem => elem.offsetWidth)

                return itemsPosition
            }
        },
        data () {
            return {
                selectedItem: 0,
                isMounted: false
            }
        },
        mounted () {
            this.selectedItem = this.selected
            this.isMounted = true
        },
        methods: {
            selectItem (id) {
                if (this.selectedItem === id) return undefined

                this.selectedItem = id
                this.$emit('change', id)
            }
        }
    }
</script>

<style lang="scss">
    .switch {
        background-color: #e4f6fd;
        border-radius: 100px;
        height: 48px;
        user-select: none;
        cursor: pointer;
        position: relative;
        justify-content: space-between;
        transition: box-shadow .3s ease;
        display: flex;
        width: 100%;

        &:hover {
            box-shadow: 0px 4px 20px rgba(10, 164, 233, 0.5);
        }

        &__button {
            background-color: #0ca9ef;
            border-radius: 100px;
            position: absolute;
            top: 0;
            bottom: 0;
            transition: all .5s ease-in-out;
            z-index: 1;
        }
    }

    .switch__item {
        display: flex;
        justify-content: center;
        z-index: 2;
        width: 100%;
    }

    .switch__text {
        cursor: pointer;
        padding: 12px 8px;
        transition: color .5s linear;
        color: #979797;

        @media (min-width: 420px) {
            padding: 14px 30px;
        }

        &--active {
            color: #fff;
            cursor: default;
        }
    }
</style>
