var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"$table",staticClass:"app-table"},[_c('div',{staticClass:"app-table__row app-table__row--header"},_vm._l((_vm.columns),function(col,index){return _c('div',{key:index,staticClass:"app-table__cell app-table__cell--header",style:({ width: _vm.styles[index] })},[_c('div',{class:{
                    'app-table__cell-inner': true,
                    'app-table__cell-inner--sortable': !!col.sort
                },on:{"click":function($event){return _vm.sort(col)}}},[_vm._t("header",[_vm._v("\n                    "+_vm._s(col.name)+"\n                ")],{"col":col}),_vm._v(" "),(col.sort)?_c('div',{staticClass:"app-table__sort"},[(_vm.sortOptions
                            && _vm.sortOptions.field === col.field
                            && _vm.sortOptions.ascending)?_c('div',{staticClass:"app-table__sort-icon"},[_vm._t("ascending-icon",[_vm._v("↑")])],2):(_vm.sortOptions
                            && _vm.sortOptions.field === col.field
                            && !_vm.sortOptions.ascending)?_c('div',{staticClass:"app-table__sort-icon"},[_vm._t("descending-icon",[_vm._v("↓")])],2):_c('div',{staticClass:"app-table__sort-icon"})]):_vm._e()],2)])}),0),_vm._v(" "),(!_vm.sorted.length)?_c('div',{staticClass:"app-table__row"},[_c('div',{staticClass:"app-table__cell app-table__cell--text-center"},[_vm._v("\n            Данные не найдены\n        ")])]):_vm._e(),_vm._v(" "),_vm._l((_vm.sorted),function(row,index){return _c('div',{key:index,staticClass:"app-table__row"},_vm._l((_vm.cells),function(cell,cellIndex){return _c('div',{key:cell,staticClass:"app-table__cell",style:({ width: _vm.styles[cellIndex] })},[_vm._t("cell",null,{"cell":row[cell],"field":cell,"row":_vm.sorted[index],"rowIndex":index,"cellIndex":cellIndex})],2)}),0)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }