<template>
    <div class="workle-recaptcha">
        <label class="recaptcha__wrapper">
            <input
                :value="value"
                name="Captcha"
                type="hidden"
            >
            <div
                ref="recaptcha"
                data-size="normal"
                :class="{
                    'recaptcha': true,
                    'recaptcha--error': errors.length
                }"
            />
        </label>
        <div
            v-if="errors.length"
            class="recaptcha__errors-wrapper"
        >
            <div
                v-for="(error, index) in errors"
                class="recaptcha__error"
                :key="index"
                v-html="error"
            />
        </div>
        <div class="recaptcha__warning">
            При использовании VPN на вашем устройстве может возникать ошибка captcha-валидации
        </div>
    </div>
</template>

<script>
    export default {
        name: 'WRecaptcha',
        props: {
            value: String,
            text: String,
            name: {
                type: String,
                required: true
            },
            errors: {
                type: Array,
                default: () => []
            }
        },
        data () {
            return {
                $_validation: {},
                captcha: null,
                failed: false,
                size: 'normal'
            }
        },
        methods: {
            reset () {
                if (!this.captcha) return undefined

                this.captcha.reset()
                this.$emit('on-reset')
                this.$emit('input', '')
            },
            onError (value) {
                this.failed = true

                this.$emit('on-error', value)
                this.$emit('input', '')
            },
            onLoaded () {
                this.$emit('on-loaded')
            },
            onSuccess (value) {
                this.failed = false

                this.$emit('on-success', value)
                this.$emit('input', value)
            }
        },
        mounted () {
            const recaptcha = Workle.namespace('recaptcha')

            recaptcha.onComponentLoaded((component) => {
                this.captcha = component

                const options = {
                    'callback': (res) => this.onSuccess(res),
                    'expired-callback': () => this.onError('expired'),
                    'error-callback': () => this.onError('misc')
                }

                this.captcha.render(this.$refs.recaptcha, options)
                this.onLoaded()
            })
        }
    }
</script>

<style lang="scss">
    @import 'Assets/scss/ui/_typography';
    @import 'Assets/scss/ui/_form-elements';

    .recaptcha {
        width: 304px; // recaptcha width
        transform: scale(.95);
        transform-origin: 0 0;

        @media only screen and (min-width: 480px) {
            transform: scale(1);
        }

        &--error {
            width: 304px;
            height: 78px;
            border: 1px solid $color-error;
            border-radius: 4px;
        }

        &__wrapper {
            display: flex;
            justify-content: center;
        }

        // Errors wrapper
        &__errors-wrapper {
            @include errors-wrapper;
        }

        // Warning text
        &__warning {
            font-size: 12px;
            color: #757575;
            margin-top: 10px;
        }
    }
</style>
