<template>
    <div
        ref="$table"
        class="app-table"
    >
        <div class="app-table__row app-table__row--header">
            <div
                v-for="(col, index) in columns"
                class="app-table__cell app-table__cell--header"
                :key="index"
                :style="{ width: styles[index] }"
            >
                <div
                    :class="{
                        'app-table__cell-inner': true,
                        'app-table__cell-inner--sortable': !!col.sort
                    }"
                    @click="sort(col)"
                >
                    <slot
                        :col="col"
                        name="header"
                    >
                        {{ col.name }}
                    </slot>
                    <div
                        v-if="col.sort"
                        class="app-table__sort"
                    >
                        <div
                            v-if="sortOptions
                                && sortOptions.field === col.field
                                && sortOptions.ascending"
                            class="app-table__sort-icon"
                        >
                            <slot name="ascending-icon">↑</slot>
                        </div>
                        <div
                            v-else-if="sortOptions
                                && sortOptions.field === col.field
                                && !sortOptions.ascending"
                            class="app-table__sort-icon"
                        >
                            <slot name="descending-icon">↓</slot>
                        </div>
                        <div
                            v-else
                            class="app-table__sort-icon"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div
            v-if="!sorted.length"
            class="app-table__row"
        >
            <div class="app-table__cell app-table__cell--text-center">
                Данные не найдены
            </div>
        </div>
        <div
            v-for="(row, index) in sorted"
            class="app-table__row"
            :key="index"
        >
            <div
                v-for="(cell, cellIndex) in cells"
                class="app-table__cell"
                :key="cell"
                :style="{ width: styles[cellIndex] }"
            >
                <slot
                    name="cell"
                    :cell="row[cell]"
                    :field="cell"
                    :row="sorted[index]"
                    :rowIndex="index"
                    :cellIndex="cellIndex"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import moment from 'moment'
    import { sortNumbers, sortStrings, sortDates } from 'Utils/sorting'

    const isAppTableColumn = (item) => {
        if (!item) return false
        if (!item.name || typeof item.name !== 'string') return false
        if (!item.field || typeof item.field !== 'string') return false
        if (item.width && typeof item.width !== 'number') return false
        if (item.sort !== undefined && typeof item.sort !== 'boolean' && typeof item.sort !== 'function') return false
        return true
    }

    export default {
        props: {
            columns: {
                type: Array,
                validator: (value) => !value.some((element) => !isAppTableColumn(element)),
                required: true
            },
            data: {
                type: Array,
                default: () => []
            },
            hideUntitled: {
                type: Boolean,
                default: false
            }
        },
        data: () => ({
            sortOptions: {},
            width: undefined
        }),
        computed: {
            cells () {
                return this.columns.map((element) => element.field)
            },
            sorted () {
                const { field, ascending } = this.sortOptions

                if (!field) return this.data

                const column = this.columns.find((element) => element.field === field)

                if (column === undefined || column.sort === undefined) {
                    return this.data
                }

                const row = this.data[0]
                const fieldType = typeof row[field]
                const fieldIsDate = fieldType === 'object'
                    && (row[field] instanceof Date || moment.isMoment(row[field]))

                if (typeof column.sort === 'function') {
                    const { sort } = column

                    return [...this.data].sort((a, b) => sort(a[field], b[field], ascending))
                }

                switch (true) {
                    case fieldType === 'number':
                        return [...this.data].sort(
                            (a, b) => sortNumbers(a[field], b[field], ascending)
                        )
                    case fieldType === 'string':
                        return [...this.data].sort(
                            (a, b) => sortStrings(a[field], b[field], ascending)
                        )
                    case fieldIsDate:
                        return [...this.data].sort(
                            (a, b) => sortDates(a[field], b[field], ascending)
                        )
                    default:
                        return [...this.data].sort(
                            (a, b) => sortNumbers(a[field], b[field], ascending)
                        )
                }
            },
            styles () {
                const totalUnspecifiedWidthColumns = this.columns.filter(
                    (element) => !element.width
                ).length
                const reminder = this.width - this.columns.reduce(
                    (acc, element) => (acc += (element.width || 0)), 0
                )
                const unspecifiedWidth = Math.ceil(
                    ((reminder / totalUnspecifiedWidthColumns) + Number.EPSILON) * 100
                ) / 100

                return this.columns.map((element) => `${element.width || unspecifiedWidth}px`)
            }
        },
        methods: {
            sort (column) {
                const { field: columnField, sort: columnSort } = column

                if (!columnField) {
                    return undefined
                }

                this.onSort(columnField)

                if (!columnSort) {
                    return undefined
                }

                const { field: sortingField, ascending: sortingAscending } = this.sortOptions

                this.sortOptions = {
                    field: columnField,
                    ascending: sortingField === columnField ? !sortingAscending : true
                }
                return undefined
            },
            onSort (field) {
                this.$emit('on-sort', field)
            },
            updateTableSizes () {
                this.width = this.$refs.$table.clientWidth || 0
            }
        },
        mounted () {
            this.updateTableSizes()
            window.addEventListener('resize', this.updateTableSizes)
        },
        destroyed () {
            window.removeEventListener('resize', this.updateTableSizes)
        }
    }
</script>

<style lang="scss">
    .app-table {
        display: flex;
        flex-direction: column;
        font-size: 14px;
        width: 100%;

        &__row {
            border-bottom: 1px solid #e8e8e8;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;

            &--header {
                background-color: #f3f3f3;
                border: none;
            }

            &:last-child {
                border: none;
            }
        }

        &__column {
            display: flex;
            flex-direction: column;
        }

        &__cell {
            color: #333;
            display: flex;
            flex-grow: 1;
            padding: 26px 20px;

            &--header {
                color: #757575;
                padding: 16px 20px;
            }

            &--text-center {
                justify-content: center;
            }
        }

        &__cell-inner {
            display: flex;

            &--sortable {
                cursor: pointer;
                user-select: none;
            }
        }

        &__sort {
            align-items: center;
            display: flex;
            padding-left: 5px;
            padding-top: 2px;
        }

        &__sort-icon {
            position: relative;
        }
    }
</style>
