<template>
    <div class="workle-checkbox">
        <label
            :class="{
                'checkbox__wrapper': true,
                'checkbox__wrapper--disabled': disabled,
            }"
        >
            <input
                type="checkbox"
                :disabled="disabled"
                :checked="value"
                :name="name"
                :class="{
                    'checkbox': true,
                    'checkbox--error': errors.length
                }"
                @change="change"
                @input="input"
            >
            <div
                :class="{
                    'checkbox__label': true,
                    'checkbox__label--bold': boldBorder
                }"
            />
            <span
                class="checkbox__label-text"
                :style="{ fontSize: fontSize }"
            >
                <slot>{{ text }}</slot>
            </span>
        </label>
        <div
            v-if="errors.length"
            class="checkbox__errors-wrapper"
        >
            <div
                v-for="(error, index) in errors"
                class="checkbox__error"
                :key="index"
                v-html="error"
            />
        </div>
    </div>
</template>

<script>
    export default {
        name: 'WCheckbox',
        props: {
            value: Boolean,
            name: {
                type: String,
                required: true
            },
            text: String,
            disabled: Boolean,
            errors: {
                type: Array,
                default: () => []
            },
            fontSize: {
                type: String,
                default: '12px'
            },
            boldBorder: {
                type: Boolean,
                default: false
            }
        },
        data () {
            return {
                $_validation: {}
            }
        },
        methods: {
            change (e) {
                this.$emit('change', e)
                this.$emit('on-change', e)
            },
            input (e) {
                this.$emit('change', e)
                this.$emit('input', e.target.checked)
            }
        }
    }
</script>

<style lang="scss">
    @import 'Assets/scss/ui/_typography';
    @import 'Assets/scss/ui/_form-elements';

    .checkbox {
        height: 0;
        opacity: 0;
        pointer-events: none;
        position: absolute;
        width: 0;

        &:checked ~ .checkbox__label {
            border-color: #2196F3;
        }

        &:checked ~ .checkbox__label::before {
            background-image: url('data:image/svg+xml;base64, PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIGlkPSJDYXBhXzEiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgNDI2LjY2NyA0MjYuNjY3IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA0MjYuNjY3IDQyNi42Njc7IiB4bWw6c3BhY2U9InByZXNlcnZlIiB3aWR0aD0iNTEyIiBoZWlnaHQ9IjUxMiIgY2xhc3M9IiI+PGc+PGc+Cgk8Zz4KCQk8cG9seWdvbiBwb2ludHM9IjM2Ni45MzMsNDIuNjY3IDE0OS4zMzMsMjYyLjQgNTkuNzMzLDE3Mi44IDAsMjMyLjUzMyAxNDkuMzMzLDM4NCA0MjYuNjY3LDEwNC41MzMgICAiIGRhdGEtb3JpZ2luYWw9IiMwMDAwMDAiIGNsYXNzPSJhY3RpdmUtcGF0aCIgc3R5bGU9ImZpbGw6IzIxOTZGMyIgZGF0YS1vbGRfY29sb3I9IiMwMDAwMDAiPjwvcG9seWdvbj4KCTwvZz4KPC9nPjwvZz4gPC9zdmc+');
            background-position: center;
            background-repeat: no-repeat;
            background-size: rem(10);
            content: '';
            height: rem(14);
            left: 1px;
            position: absolute;
            top: 1px;
            width: rem(14);
        }

        &--error ~ .checkbox__label {
            border: $border-input-error;
        }

        &--error ~ .checkbox__label-text {
            color: $color-error;
        }

        &__wrapper {
            cursor: pointer;
            display: inline-flex;
            flex-direction: row;
            justify-content: flex-start;
            position: relative;

            &--disabled {
                cursor: default;
            }
        }

        &__label {
            background-color: #fff;
            border: 1px solid #ccc;
            border-radius: 3px;
            box-sizing: border-box;
            flex: 0 0 rem(16);
            height: rem(16);
            width: rem(16);

            &--bold {
                border: 2px solid #ccc;
            }
        }

        &__label-text {
            box-sizing: content-box;
            font-size: rem(12);
            line-height: rem(16);
            max-width: calc(100% - rem(22));
            padding-left: rem(10);
            vertical-align: middle;
            user-select: none;
        }

        &__errors-wrapper {
            color: $color-error;
            font-size: rem(12);
            padding-left: rem(26);
        }
    }
</style>
