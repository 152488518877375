<template>
    <component
        class="w-link"
        :is="href ? 'a' : 'div'"
        :href="href || null"
        @click="click"
    >
        <div
            v-if="hasIcon"
            class="w-link__prepend"
        >
            <slot
                class="w-link__icon"
                name="icon"
            />
        </div>
        <span
            v-if="text"
            class="w-link__text"
            v-text="text"
        />
    </component>
</template>

<script>
    export default {
        name: 'WLink',
        props: {
            text: {
                type: String,
                default: ''
            },
            href: {
                type: String,
                default: ''
            },
            onClick: {
                type: Function,
                default: function (e) {
                    if (this.href) return false

                    this.$emit('click', e)
                }
            }
        },
        computed: {
            hasIcon () {
                return !!this.$slots.icon
            }
        },
        methods: {
            click (e) {
                this.onClick(e)
            }
        }
    }
</script>

<style lang="scss">
    @import 'Assets/scss/ui/_typography';
    @import 'Assets/scss/ui/_form-elements';

    .w-link {
        align-items: center;
        box-sizing: border-box;
        border-radius: 15px;
        cursor: pointer;
        display: inline-flex;
        font-family: $font-PT-Sans;
        font-size: rem(18);
        text-decoration: none;

        &:hover &__prepend {
            background-color: #0ca9ef;
            fill: #fff;
        }

        &:hover &__text {
            color: #0ca9ef;
        }

        &__text {
            color: #333;
            transition: color .1s ease-in-out;
            white-space: nowrap;
        }

        &__prepend {
            display: flex;
            justify-content: center;
            align-items: center;
            fill: #333;
            width: 30px;
            height: 30px;
            min-width: 30px;
            min-height: 30px;
            background-color: #e8e8e8;
            border-radius: 50%;
            margin-right: 10px;
            transition: fill .1s ease-in-out;
            transition: background-color .1s ease-in-out;
        }
    }
</style>
