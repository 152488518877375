<template>
    <div class="workle-recaptcha workle-recaptcha--invisible">
        <div ref="recaptcha" :id="widgetId" />
    </div>
</template>

<script>
    export default {
        name: 'WRecaptchaInvisible',
        props: {
            value: String,
            widgetId: {
                type: String,
                required: true
            }
        },
        data () {
            return {
                captcha: null,
                failed: false
            }
        },
        methods: {
            execute () {
                if (!this.captcha) return undefined

                this.captcha.execute(this.widgetId)
            },
            reset () {
                if (!this.captcha) return undefined

                this.captcha.reset()
                this.$emit('on-reset')
                this.$emit('input', '')
            },
            onError (value) {
                this.failed = true

                this.$emit('on-error', value)
                this.$emit('input', '')
            },
            onLoaded () {
                this.$emit('on-loaded')
            },
            onSuccess (value) {
                this.failed = false

                this.$emit('on-success', value)
                this.$emit('input', value)
            }
        },
        mounted () {
            const recaptcha = Workle.namespace('recaptcha')

            recaptcha.onComponentLoaded((component) => {
                this.captcha = component

                const options = {
                    'size': 'invisible',
                    'callback': (res) => this.onSuccess(res),
                    'expired-callback': () => this.onError('expired'),
                    'error-callback': () => this.onError('misc')
                }

                this.captcha.render(this.$refs.recaptcha, options)
                this.onLoaded()
            })
        }
    }
</script>

<style lang="scss">
    /* styles */
</style>
