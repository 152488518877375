<template>
    <div class="workle-textarea">
        <label class="textarea__wrapper">
            <textarea
                :value="value"
                :class="{
                    'textarea': true,
                    'textarea--filled': value,
                    'textarea--no-text': !text.length,
                    'textarea--error': errors.length,
                    'textarea--disabled': disabled,
                    'textarea--resizable': resizable
                }"
                :name="name"
                :rows="rows"
                :disabled="disabled"
                :readonly="readonly"
                :placeholder="!disabled ? placeholder : ''"
                @input="onInput"
                @change="onChange"
                @blur="onBlur"
                @copy="onCopy"
            />
            <div
                v-if="text.length"
                class="textarea__text"
            >
                <slot name="text">
                    {{ text }}
                </slot>
            </div>
        </label>
        <div
            v-if="errors.length"
            class="textarea__errors-wrapper"
        >
            <div
                v-for="(error, index) in errors"
                class="textarea__error"
                :key="index"
                v-html="error"
            />
        </div>
    </div>
</template>

<script>
    import { debounce } from 'Utils/helpers'

    export default {
        name: 'WTextarea',
        props: {
            value: [ String, Number ],
            name: {
                type: String,
                required: true
            },
            text: {
                type: String,
                default: ''
            },
            rows: {
                type: Number,
                default: 2
            },
            disabled: {
                type: Boolean,
                default: false
            },
            readonly: {
                type: Boolean,
                default: false
            },
            placeholder: {
                type: String,
                default: ' '
            },
            debounce: {
                type: Number,
                default: 0
            },
            errors: {
                type: Array,
                default: () => []
            },
            resizable: {
                type: Boolean,
                default: true
            }
        },
        data () {
            return {
                $_validation: {},
                lastDebounceValue: ''
            }
        },
        methods: {
            onInput (e) {
                this.$emit('input', e.target.value)
                this.$nextTick(() => {
                    if (this.debounce > 0) {
                        this.onDebounce(this, e.target.value)
                    }
                })
            },
            onChange (e) {
                /*
                    TODO: fix that stuff, we must not send 'input' when its actually 'change'
                    This is hotfix for validation. If we'll uncomment 'change' line and comment 'input',
                    it can be tested on registration login field with ctrl+v

                    1) Write bad login (not email/phone)
                    2) unfocus field
                    3) ctrl+v valid phone
                    4) field error not dissapearing
                */

                // this.$emit('change', e.target.value)
                this.$emit('input', e.target.value)
            },
            onDebounce: debounce((context, val) => {
                if (context.lastDebounceValue === val) return undefined

                context.lastDebounceValue = val
                context.$emit('on-debounce', val)
            }, 1000),
            onBlur (e) {
                this.$emit('blur', e)
            },
            onCopy (e) {
                this.$emit('copy', e.target.value)
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import 'Assets/scss/ui/_typography';
    @import 'Assets/scss/ui/_form-elements';

    .textarea {
        box-sizing: border-box;
        appearance: none;
        font-size: 0; /* DIRTY PLACEHOLDER HACK */
        font-family: $font-PT-Sans;
        border-radius: $border-radius-input;
        letter-spacing: normal;
        line-height: rem(16);
        height: $height-textarea-md;
        color: $color-default;
        padding: rem(16) rem(14);
        border: $border-input;
        transition: padding .3s ease 0s;
        outline: none;
        resize: none;
        width: 100%;

        &:not(:placeholder-shown),
        &:active,
        &:focus,
        &--filled,
        &--no-text {
            font-size: $font-size-default;
            line-height: rem(16);
        }

        &--error {
            border: $border-input-error;
        }

        &--disabled {
            background-color: #eee;
            cursor: default;
            opacity: 1;
        }

        &--resizable {
            resize: vertical;

            &::-webkit-scrollbar {
                width: 5px;
            }

            &::-webkit-scrollbar-track {
                background-color: #ebebeb;
                border-radius: 100px;
            }

            &::-webkit-scrollbar-thumb {
                border-radius: 100px;
                background-color: #333;
            }
        }

        &__wrapper {
            display: inline-block;
            //line-height: rem(14);
            position: relative;
            width: 100%;
            text-align: left;
        }

        // Label
        &__text {
            @include label;
        }

        // Errors wrapper
        &__errors-wrapper {
            @include errors-wrapper;
        }

        // Label transitions
        &:focus + &__text,
        &--filled + &__text,
        &:not(:placeholder-shown) + &__text {
            @include label-active;
        }

        &--error + &__text {
            @include label-error;
        }

        &:disabled + &__text{
            @include label-disabled;
        }
    }
</style>
