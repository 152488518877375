export const debounce = (func, wait, immediate = false) => {
    let timeout = null
  
    return function executedFunction() {
        const context = this
        const args = arguments

        clearTimeout(timeout)

        timeout = setTimeout(() => {
            timeout = null

            if (!immediate) {
                func.apply(context, args)
            }
        }, wait)
        
        if (immediate && timeout === null) {
            func.apply(context, args)
        }
    }
}

export const throttle = (func, wait, options) => {
    let context, args, result
    let timeout = null
    let previous = 0

    if (!options) options = {}

    const later = function () {
        previous = options.leading === false ? 0 : Date.now()
        timeout = null
        result = func.apply(context, args)

        if (!timeout) {
            context = null
            args = null
        }
    }

    return function() {
        const now = Date.now()

        if (!previous && options.leading === false) previous = now

        const remaining = wait - (now - previous)

        context = this
        args = arguments

        if (remaining <= 0 || remaining > wait) {
            if (timeout) {
                clearTimeout(timeout)
                timeout = null
            }

            previous = now
            result = func.apply(context, args)

            if (!timeout) {
                context = null
                args = null
            }

        } else if (!timeout && options.trailing !== false) {
            timeout = setTimeout(later, remaining)
        }

        return result
    }
}
