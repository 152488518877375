import moment from 'moment'


export const sortNumbers = (a, b, ascending) => {
    return !ascending
        ? a - b
        : b - a
}

export const sortStrings = (a, b, ascending) => {
    return !ascending
        ? String(a).localeCompare(b, undefined, { numeric: true })
        : String(b).localeCompare(a, undefined, { numeric: true })
}

export const sortDates = (a, b, ascending) => {
    return !ascending
        ? moment(a).diff(moment(b))
        : moment(b).diff(moment(a))
}
