import Ajv from 'ajv'
import * as AjvErrors from 'ajv-errors'

const ajv = new Ajv({
    $data: true,
    allErrors: true,
    jsonPointers: true,
    errorDataPath: 'property'
})

AjvErrors(ajv)

const ValidationError = function (errors = []) {
    this.name = 'ValidationError'
    this.message = {
        message: 'You have errors in following fields!',
        errors
    }
    this.stack = (new Error()).stack
}

ValidationError.prototype = Object.create(Error.prototype)
ValidationError.prototype.constructor = ValidationError

const compileSchema = async function (schema) {
    return new Promise((resolve, reject) => {
        try {
            const validation = ajv.compile(schema)

            resolve(validation)
        } catch (error) {
            reject(error)
        }
    })
}

const hasNestedValue = function (name) {
    return (new RegExp(/\./)).test(name)
}

const hasField = function (field = null) {
    return field && field.length && this.errors.hasOwnProperty(field)
}

const hasErrors = function (field = null) {
    if (!field) return false

    return this.errors.hasOwnProperty(field) ?
        this.errors[field].length > 0 :
        false
}

const getNestedFieldValue = function (name, value) {
    const regexp = new RegExp(/([a-z0-9]+)\.(?=([a-z]+))/, 'gi')
    const matches = [ ...name.matchAll(regexp) ].reverse()

    return matches.reduce((acc, match, curr) => {
        const [ , prop, el ] = match
        const isArray = /^\d+$/.test(prop)

        if (isArray) {
            const arr = new Array(parseInt(prop, 10))

            arr.push({ [ el ]: curr === 0 ? value : acc })

            return arr
        }

        const data = {}

        data[el] = curr === 0 ? value : acc

        return data
    }, null)
}

const getErrors = function (field = null) {
    if (!field || !field.length) return this.errors
    if (!Array.isArray(field)) return this.errors[field] || []

    return field.reduce((acc, el) => acc.concat(this.errors[el] || []), [])
}

const setErrors = function (field = null, errors = [], force = false) {
    if (!this.hasField(field) && !force) return undefined

    return this.errors[field] = errors
}

const validate = async function (schema, data) {
    try {
        await ValidateSchema(schema, data)
        Object.keys(this.errors).forEach((key) => this.errors[key] = [])
    } catch (err) {
        Object.keys(this.errors).forEach((key) => this.errors[key] = err[key] || [])

        throw new ValidationError(err)
    }
}

const ValidateMixin = {
    data () {
        return {
            $_validation: {
                initialized: true,
                valid: false,
                scope: null,
                errors: {},
                hasField,
                hasErrors,
                getErrors,
                setErrors,
                validate
            }
        }
    },
    computed: {
        validation () {
            return this.$data.$_validation
        }
    },
    watch: {
        '$data.$_validation.errors': {
            deep: true,
            handler (val) {
                const errors = []

                Object.keys(val).forEach((key) => {
                    errors.concat(val[key])
                })

                this.$data.$_validation.valid = errors.length === 0
            }
        }
    }
}

const ValidateFieldDirective = {
    bind (el, binding, vnode) {
        const component = el.__vue__
        const settings = binding.value || {}

        let {
            blur,
            change,
            input,
            collapse,
            lazy,
            eager
        } = binding.modifiers

        if (!settings.rules || !settings.rules.properties) {
            // TODO: check if shema type object, then properties needed
            console.warn(`[${binding.name}]: Element must have 'JSON Schema' set of rules :`, el)

            return undefined
        }

        if (!component.name) {
            console.warn(`[${binding.name}]: Element must have 'name' attribute:`, el)

            return undefined
        }

        if (!vnode.context.$data.$_validation) {
            console.warn(`[${binding.name}]: Add 'ValidateMixin' to your root component`, vnode.context._vnode.elm)

            return undefined
        }

        if (!blur && !change && !input && !collapse) {
            blur = true
        }

        // Create field errors array
        vnode.context.$set(vnode.context.$data.$_validation.errors, component.name, [])

        // TODO: Actually we can move it to mixin
        component.$set(component.$data, '$_validation', {
            initial: JSON.parse(JSON.stringify(component.value)),
            validated: false,
            valid: false,
            dirty: false,
            scope: null,
            errors: []
        })

        const $_validation = component.$data.$_validation
        const validateComponent = async (field, value, eagerCheck = false) => {
            if ($_validation.validated) return undefined

            // TODO: need deep comparison for objects and arrays
            const transform = typeof settings.transform === 'function' ? settings.transform : (val) => val
            const success = typeof settings.success === 'function' ? settings.success : () => {}
            const changed = JSON.stringify($_validation.initial) !== JSON.stringify(value)
            const data = {}

            if (hasNestedValue(field)) {
                const parentKey = field.substr(0, field.indexOf('.'))
                const parentKeyValue = getNestedFieldValue(field, transform(value))

                data[parentKey] = parentKeyValue
            } else {
                data[field] = transform(value)
            }

            // Do not mark field as dirty if it was eagerCheck
            if (!$_validation.dirty && changed && !eagerCheck) {
                $_validation.dirty = true
            }

            try {
                await ValidateSchema(settings.rules, data, field)

                vnode.context.$set(vnode.context.$data.$_validation.errors, field, [])
                vnode.context.$set($_validation, 'errors', [])

                $_validation.valid = true
                $_validation.validated = true

                success()
            } catch (errors) {
                if (!changed && lazy) return undefined
                if (!$_validation.dirty && eagerCheck) return undefined

                vnode.context.$set(vnode.context.$data.$_validation.errors, field, errors)
                vnode.context.$set($_validation, 'errors', errors)

                // TODO: do we need line below?
                $_validation.valid = false
                // Do not move to finally cause it will be triggered even if eagerCheck
                $_validation.validated = true
            }
        }

        component.$on('blur', (event) => {
            if (!blur) return undefined

            validateComponent(component.name, event.target.value)
        })

        component.$on('change', (event) => {
            if (!change) return undefined

            const isCheckbox = event.target.type === 'checkbox'
            const value = !isCheckbox ? event.target.value : event.target.checked

            validateComponent(component.name, value)
        })

        component.$on('input', (value) => {
            // TODO: do I need that line?
            $_validation.validated = false

            if (!input && !eager) {
                return undefined
            }

            const isEagerCheck = !input && eager

            validateComponent(component.name, value, isEagerCheck)
        })

        // 'collapse' event triggered when
        component.$on('collapse', (value) => {
            if (!collapse) return undefined

            validateComponent(component.name, value)
        })
    }
}

const ValidateSchema = (schema, data, field = null) => {
    return new Promise(async (resolve, reject) => {
        try {
            const validation = await compileSchema(schema)
            const valid = validation(data)
            const errors = {}

            if (!valid) {
                validation.errors.forEach((el) => {
                    if (!el.dataPath) return undefined

                    // Looks like a crab... \/0_o\/
                    const nestedArrayParamsRegExp = new RegExp('\/([0-9])\/', 'g')
                    const nestedArrayParamsReplace = (...args) => (`.${args[1]}.`)
                    const nestedParamsRegExp = new RegExp('\/', 'g')
                    const prop = el.dataPath
                        .replace('/', '')
                        .replaceAll(nestedArrayParamsRegExp, nestedArrayParamsReplace)
                        .replaceAll(nestedParamsRegExp, '.')

                    if (!errors[prop]) {
                        errors[prop] = []
                    }

                    errors[prop].push(el.message)
                })

                if (!field) {
                    return reject(errors)
                }

                // Single field validation for whole schema
                // TODO: rewrite, not actually efficient enough
                if (!errors[field]) {
                    return resolve()
                }

                return reject(errors[field])
            }

            return resolve()
        } catch (error) {
            const message = 'Ошибка валидации'

            console.error(error)

            return !field
                ? reject({ unknown: [ message ] })
                : reject([ message ])
        }
    })
}

export {
    ValidateFieldDirective,
    ValidateMixin,
    ValidateSchema
}
