<template>
    <div class="app-form">
        <div class="app-form__fields">
            <div
                v-for="(field, index) of fields"
                class="app-form__field-wrapper"
                :key="index"
            >
                <slot
                    name="field"
                    :field="field"
                    :index="index"
                />
            </div>
        </div>
        <div class="app-form__buttons">
            <div
                v-for="(button, index) of buttons"
                class="app-form__button-wrapper"
                :key="index"
            >
                <slot
                    name="button"
                    :button="button"
                    :index="index"
                />
            </div>
            <div
                class="app-form__button"
                @click="submit"
            >
                <slot name="submit" />
            </div>
        </div>
    </div>
</template>

<script>
    import { ValidateMixin } from 'Components/js/utils/validation'

    const isField = (field) => {
        if (!field) return false
        if (field.alias || typeof field.alias !== 'string') return false
        if (field.type || typeof field.type !== 'string') return false
        if (field.value) return false

        return true
    }

    const isButton = (button) => {
        if (!button) return false
        if (button.alias || typeof button.alias !== 'string') return false

        return true
    }

    export default {
        mixins: [ValidateMixin],
        props: {
            fields: {
                type: Array,
                required: true,
                validator: (value) => !value.some((field) => isField(field))
            },
            buttons: {
                type: Array,
                required: true,
                validator: (value) => !value.some((button) => isButton(button))
            },
            rules: {
                type: Object,
                required: true
            }
        },
        methods: {
            onErrors (errors) {
                this.$emit('error', errors)
            },
            async submit () {
                try {
                    const data = this.fields.reduce((object, field) => {
                        if (field.split) {
                            return {
                                ...object,
                                ...(field.transform ? field.transform(field.value) : field.value)
                            }
                        }

                        return {
                            ...object,
                            [field.alias]: field.transform ? field.transform(field.value) : field.value
                        }
                    }, {})

                    await this.validation.validate(this.rules, data)

                    this.$emit('submit', data)
                } catch (errors) {
                    console.warn(errors)
                    this.onErrors(errors)
                }
            }
        }
    }
</script>

<style lang="scss">
    .app-form {
        &__buttons {
            display: flex;
        }

        &__button-wrapper {
            margin-right: 10px;
        }
    }
</style>
