<template>
    <div class="app-dropdown-menu">
        <div
            class="app-dropdown-menu__handler"
            ref="$handler"
            @click="onToggle"
        >
            <slot />
        </div>
        <transition name="fade">
            <div
                v-if="isVisible"
                :class="{
                    'app-dropdown-menu__list-positioning-helper': true,
                    [`app-dropdown-menu__list-positioning-helper--${position}`]: true
                }"
            >
                <div
                    :class="{
                        'app-dropdown-menu__list-arrow': true,
                        [`app-dropdown-menu__list-arrow--${position}`]: true
                    }"
                />
                <div
                    ref="$list"
                    tabindex="0"
                    :class="{
                        'app-dropdown-menu__list': true,
                        [`app-dropdown-menu__list--${position}`]: true
                    }"
                    :style="{
                        minWidth: width ? `${width}px` : null,
                        top: offsetBottom ? `${offsetBottom}px` : null
                    }"
                    @focusout="onHide"
                >
                    <section
                        v-for="(section, index) in sections"
                        class="app-dropdown-menu__section"
                        :key="index"
                    >
                        <div
                            v-for="item in section"
                            class="app-dropdown-menu__section-item"
                            :key="item.alias"
                            :style="{ color: item.color || null }"
                            @click.prevent="onItemClick(item.alias)"
                        >
                            <slot
                                name="item"
                                :item="item"
                            >
                                {{ item.name }}
                            </slot>
                        </div>
                    </section>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
    import Vue from 'vue'

    const isAppDropdownMenuSectionItem = (item) => {
        if (!item) return false
        if (item.name === undefined || typeof item.name !== 'string') return false
        return true
    }

    const isAppDropdownMenuSection = (item) => {
        if (!Array.isArray(item)) return false
        if (item.some((el) => !isAppDropdownMenuSectionItem(el))) return false
        return true
    }

    export default {
        props: {
            sections: {
                type: Array,
                validator: (value) => (
                    !value.some((el) => !isAppDropdownMenuSection(el))
                ),
                required: true
            },
            width: {
                type: Number,
                default: null
            },
            position: {
                type: String,
                default: 'left',
                validator: (value) => {
                    const allowed = ['left', 'right']

                    return allowed.includes(value)
                }
            },
            closeAfterClick: {
                type: Boolean,
                default: false
            }
        },
        data: () => ({
            offsetBottom: 0,
            isVisible: false
        }),
        methods: {
            getOffsetBottom () {
                if (!this.$refs.$list || !this.$refs.$handler.value) return null

                const PADDING_BOTTOM = 20
                const handlerHeight = this.$refs.$handler.value.offsetHeight
                const listHeight = this.$refs.$list.offsetHeight + PADDING_BOTTOM
                const bottomGapHeight = window.innerHeight
                    - (this.$refs.$list.getBoundingClientRect().top + listHeight)
                const bottomGapHeightRaw = bottomGapHeight + (this.offsetBottom || 0)

                if ( bottomGapHeightRaw < 0 && bottomGapHeightRaw <= -(listHeight - handlerHeight - PADDING_BOTTOM)) {
                    return -(listHeight - handlerHeight) + PADDING_BOTTOM
                }

                if (bottomGapHeightRaw < 0) {
                    return bottomGapHeightRaw
                }

                return 0
            },
            setOffsetBottom () {
                const offset = this.getOffsetBottom()

                this.offsetBottom = offset
            },
            onItemClick (alias) {
                this.$emit('on-click', alias)

                if (this.closeAfterClick) {
                    this.onHide()
                }
            },
            onToggle () {
                return this.isVisible ? this.onHide() : this.onShow()
            },
            onShow () {
                this.isVisible = true

                Vue.nextTick(() => {
                    if (this.$refs.$list === null) return undefined

                    this.setOffsetBottom()
                    document.addEventListener('scroll', this.setOffsetBottom)

                    this.$refs.$list.focus({ preventScroll: true })

                    return undefined
                })
            },
            onHide () {
                this.isVisible = false

                document.removeEventListener('scroll', this.setOffsetBottom)
            }
        }
    }
</script>

<style lang="scss">
    .app-dropdown-menu {
        display: flex;
        position: relative;

        &__list-positioning-helper {
            display: flex;
            height: 100%;
            position: absolute;
            top: 0px;
            width: 0;
            z-index: 10;

            &--left {
                left: 0;
            }

            &--right {
                right: 0;
            }
        }

        &__list {
            background-color: #fff;
            border-radius: 8px;
            box-shadow: 4px 10px 30px rgba(0, 0, 0, .3);
            outline: none;
            padding: 8px 20px;
            position: absolute;

            &--left {
                right: 10px;
            }

            &--right {
                left: 10px;
            }
        }

        &__list-arrow {
            border-bottom: 6px solid transparent;
            border-top: 6px solid transparent;
            height: 0;
            position: absolute;
            top: 10px;
            width: 0;
            z-index: 1;

            &--left {
                border-left: 6px solid #fff;
                left: -10px;
            }

            &--right {
                border-right: 6px solid #fff;
                right: -10px;
            }
        }

        &__section {
            border-bottom: 1px solid #e0e0e0;
            display: flex;
            flex-direction: column;
            padding: 6px 20px 6px 0;

            &:first-child {
                padding-top: 0;
            }

            &:last-child {
                border: none;
                padding-bottom: 0;
            }
        }

        &__section-item {
            color: #979797;
            cursor: pointer;
            font-size: 14px;
            line-height: 1;
            padding: 8px 0;
            position: relative;
            text-decoration: none;
            transition: color .15s ease-in-out;
            white-space: nowrap;

            &:hover {
                color: #333;
            }
        }

        &__section-item-link {
            color: #979797;
        }

        &__handler {
            cursor: pointer;
            display: flex;
            padding: 6px 0;
            z-index: 1;
        }
    }
</style>
