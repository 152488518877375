var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"workle-textarea"},[_c('label',{staticClass:"textarea__wrapper"},[_c('textarea',{class:{
                'textarea': true,
                'textarea--filled': _vm.value,
                'textarea--no-text': !_vm.text.length,
                'textarea--error': _vm.errors.length,
                'textarea--disabled': _vm.disabled,
                'textarea--resizable': _vm.resizable
            },attrs:{"name":_vm.name,"rows":_vm.rows,"disabled":_vm.disabled,"readonly":_vm.readonly,"placeholder":!_vm.disabled ? _vm.placeholder : ''},domProps:{"value":_vm.value},on:{"input":_vm.onInput,"change":_vm.onChange,"blur":_vm.onBlur,"copy":_vm.onCopy}}),_vm._v(" "),(_vm.text.length)?_c('div',{staticClass:"textarea__text"},[_vm._t("text",[_vm._v("\n                "+_vm._s(_vm.text)+"\n            ")])],2):_vm._e()]),_vm._v(" "),(_vm.errors.length)?_c('div',{staticClass:"textarea__errors-wrapper"},_vm._l((_vm.errors),function(error,index){return _c('div',{key:index,staticClass:"textarea__error",domProps:{"innerHTML":_vm._s(error)}})}),0):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }