var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"workle-checkbox"},[_c('label',{class:{
            'checkbox__wrapper': true,
            'checkbox__wrapper--disabled': _vm.disabled,
        }},[_c('input',{class:{
                'checkbox': true,
                'checkbox--error': _vm.errors.length
            },attrs:{"type":"checkbox","disabled":_vm.disabled,"name":_vm.name},domProps:{"checked":_vm.value},on:{"change":_vm.change,"input":_vm.input}}),_vm._v(" "),_c('div',{class:{
                'checkbox__label': true,
                'checkbox__label--bold': _vm.boldBorder
            }}),_vm._v(" "),_c('span',{staticClass:"checkbox__label-text",style:({ fontSize: _vm.fontSize })},[_vm._t("default",[_vm._v(_vm._s(_vm.text))])],2)]),_vm._v(" "),(_vm.errors.length)?_c('div',{staticClass:"checkbox__errors-wrapper"},_vm._l((_vm.errors),function(error,index){return _c('div',{key:index,staticClass:"checkbox__error",domProps:{"innerHTML":_vm._s(error)}})}),0):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }