var render = function () {
var _obj, _obj$1;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition',{attrs:{"name":"fade","duration":{
        enter: _vm.duration,
        leave: _vm.duration
    }}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.visible),expression:"visible"}],staticClass:"w-modal",on:{"mousedown":function($event){if($event.target !== $event.currentTarget){ return null; }return _vm.close(true)},"touchstart":function($event){if($event.target !== $event.currentTarget){ return null; }return _vm.close(true)}}},[_c('div',{ref:"content",class:( _obj = {
                'w-modal__content': true
            }, _obj[ 'w-modal__content--' + _vm.size ] = true, _obj )},[_c('div',{ref:"header",class:{
                    'w-modal__header': true,
                    'w-modal__header--compact': _vm.size === 'compact'
                }},[(_vm.closable)?_c('div',{staticClass:"w-modal__actions w-modal__actions--top"},[_c(_vm.size === 'compact' ? 'close-icon' : 'close-circle-icon',{tag:"component",class:{
                            'w-modal__icon-close': true,
                            'w-modal__icon-close--solid': _vm.closeSolid,
                        },on:{"click":_vm.close}})],1):_vm._e(),_vm._v(" "),_vm._t("header",[_c('h2',{class:( _obj$1 = {
                            'w-modal__header-title': true
                        }, _obj$1[ 'w-modal__header-title--' + _vm.size ] = true, _obj$1 ),domProps:{"textContent":_vm._s(_vm.title)}})])],2),_vm._v(" "),(_vm.size === 'compact')?_c('div',{staticClass:"w-modal__separator"}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"w-modal__body"},[_vm._t("body")],2),_vm._v(" "),_c('div',{staticClass:"w-modal__footer"},[(_vm.showConfirmButtons)?_c('div',{staticClass:"w-modal__buttons"},[_c('w-button',{staticClass:"w-modal__buttons-item",on:{"click":_vm.confirm}},[_vm._v("\n                        Да\n                    ")]),_vm._v(" "),_c('w-button',{staticClass:"w-modal__buttons-item",attrs:{"color":"gray","color-to":"green"},on:{"click":_vm.cancel}},[_vm._v("\n                        Нет\n                    ")])],1):_vm._e(),_vm._v(" "),_vm._t("footer")],2),_vm._v(" "),(_vm.decoration !== 'normal')?_c('div',{ref:"decoration",class:{
                    'w-modal__decoration': true,
                    'w-modal__decoration--purple': _vm.decoration.includes('purple')
                }},[(_vm.decoration.includes('wavy'))?_c('wave-mask',{staticClass:"w-modal__decoration-mask w-modal__decoration-mask--wave"}):_vm._e()],1):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }